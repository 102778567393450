import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faAngleRight, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import bordGaisEnergyLogo from '../assets/svg/clients-logo/bord-gais-energy-logo.svg';
import roomexLogo from '../assets/svg/clients-logo/roomex-logo.svg';
import schedulinoLogo from '../assets/svg/clients-logo/schedulino-logo.svg';
import dot2Svg from '../assets/svg/components/dots-2.svg';
import ipadSvg from '../assets/svg/components/ipad.svg';
import iphoneX from '../assets/svg/components/iphone-x.svg';
import cloudHostingSvg from '../assets/svg/illustrations/cloud-hosting-amico.svg';
import codingSvg from '../assets/svg/illustrations/coding-amico.svg';
import webAppSvg from '../assets/svg/illustrations/javascript-frameworks-amico.svg';
import programmerSvg from '../assets/svg/illustrations/programmer-amico.svg';
import angularIcon from '../assets/svg/technologies/angular.svg';
import awsIcon from '../assets/svg/technologies/aws.svg';
import gatsbyIcon from '../assets/svg/technologies/gatsby_icon.svg';
import jsIcon from '../assets/svg/technologies/js.svg';
import nodeJsIcon from '../assets/svg/technologies/nodejs.svg';
import reactIcon from '../assets/svg/technologies/react.svg';
import rxjsIcon from '../assets/svg/technologies/rxjs.svg';
import serverlessIcon from '../assets/svg/technologies/serverless_icon.svg';
import stripeIcon from '../assets/svg/technologies/stripe_icon.svg';
import typescriptIcon from '../assets/svg/technologies/typescript.svg';
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/Seo';
import SocialProof from '../components/SocialProof/SocialProof';
import SVG from '../components/Svg';

const IndexPage = (/* { data } */) => {
  // const posts = data.allMarkdownRemark.nodes;
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={t('meta.home.title')}
        lang={i18n.language}
        description={t('meta.home.description')}
      />

      <section className="space-top-3 space-top-lg-2 space-bottom-2 space-bottom-lg-3">
        <div className="gradient-y-gray position-relative">
          <div className="container">
            <div className="row justify-content-lg-between mb-4 mb-lg-0">
              <div className="col-lg-6">
                <div className="mb-4">
                  <h1 className="display-5">
                    {t('homePage.title_1')} (
                    <span className="text-highlight-primary">{t('homePage.title_2')}</span>)
                  </h1>
                  <p>{t('homePage.subtitle')}</p>
                </div>

                <div>
                  <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover">
                    {t('homePage.cta')}
                  </LocalizedLink>
                </div>
              </div>

              <div className="col-lg-6 d-none d-lg-inline-block align-self-center">
                <figure className="w-100 text-center">
                  <img
                    className="img-fluid w-lg-85"
                    src={programmerSvg}
                    alt="programmer illustration"
                  />
                </figure>
              </div>
            </div>

            <SocialProof />
          </div>
          <figure>
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
            >
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
            </svg>
          </figure>
        </div>
      </section>

      <section className="position-relative bg-white">
        <div className="position-absolute w-100" data-aos="fade-up">
          <div className="container-fluid px-lg-5">
            <div className="row justify-content-center">
              <div className="col-3 my-4">
                <div
                  className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-1 mx-auto"
                  data-aos="fade-up"
                >
                  <img className="avatar-img" src={angularIcon} alt="angular" />
                </div>
              </div>
              <div className="col-3 mb-4">
                <div
                  className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-1 mx-auto"
                  data-aos="fade-up"
                >
                  <img className="avatar-img" src={awsIcon} alt="aws" />
                </div>
              </div>
              <div className="col-3 my-4">
                <div
                  className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-2 mx-auto"
                  data-aos="fade-up"
                >
                  <img className="avatar-img" src={nodeJsIcon} alt="node.js" />
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-3 mb-4">
                <div
                  className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img className="avatar-img" src={typescriptIcon} alt="typescript" />
                </div>
              </div>
              <div className="col-3 my-4">
                <div
                  className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img className="avatar-img" src={serverlessIcon} alt="serverless" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3 offset-1 my-4">
                <div
                  className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 ml-auto"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img className="avatar-img" src={gatsbyIcon} alt="gatsby" />
                </div>
              </div>
              <div className="col-3 offset-2 my-4">
                <div
                  className="d-none d-md-block bg-white avatar avatar-xl shadow-sm rounded-circle p-3 ml-auto"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <img className="avatar-img" src={jsIcon} alt="javascript" />
                </div>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-4">
                <div
                  className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img className="avatar-img" src={stripeIcon} alt="stripe" />
                </div>
              </div>
              <div className="col-4 mt-6">
                <div
                  className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-4 mx-auto"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img className="avatar-img" src={reactIcon} alt="react" />
                </div>
              </div>
              <div className="col-4 mt-6">
                <div
                  className="d-block bg-white avatar avatar-xl shadow-sm rounded-circle p-2 mx-auto"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img className="avatar-img" src={rxjsIcon} alt="rxjs" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container position-relative z-index-2 space-top-3 space-bottom-2 space-bottom-lg-3">
          <div className="row flex-column align-items-center text-center">
            <h2 className="col-lg-7 mb-5 mt-3">{t('homePage.solution.title')}</h2>
            <p className="col-lg-5">{t('homePage.solution.text_1')}</p>
            <p className="col-lg-5">{t('homePage.solution.text_2')}</p>
            <p className="col-lg-5">{t('homePage.solution.text_3')}</p>
          </div>
        </div>

        <div className="position-absolute top-0 left-0 w-100 h-100 gradient-y-lg-white" />
      </section>

      <SVG />

      <section className="bg-light">
        <div className="container space-2 space-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-6 mb-5 mb-lg-0" data-aos="fade-right">
              <div className="mb-4">
                <h2>{t('homePage.services.1.title')}</h2>
                <p>{t('homePage.services.1.subtitle')}</p>
              </div>
              <div className="media">
                <StaticImage
                  className="rounded-circle mr-3"
                  src="../assets/images/testimonials/avatar_roomex.jpeg"
                  alt="avatar"
                  layout="fixed"
                  width={50}
                  height={50}
                />
                <div className="media-body">
                  <i>“{t('homePage.services.1.testimonial')}”</i>
                  <div className="mt-2">
                    <h4 className="mb-0">Karl Glennon</h4>
                    <small className="d-block">Co-Founder & CTO at Roomex</small>
                  </div>
                </div>
              </div>
            </div>
            <figure className="col-lg-6 text-center" data-aos="fade-left">
              <img className="img-fluid w-lg-80" src={codingSvg} alt="coding illustration" />
            </figure>
          </div>
        </div>
      </section>

      <SVG type="ShapeBottom" />

      <section className="container space-top-lg-3 space-2">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-lg-6 order-lg-2 mb-5 mb-lg-0" data-aos="fade-left">
            <div className="mb-4">
              <h2>{t('homePage.services.2.title')}</h2>
              <p>{t('homePage.services.2.subtitle')}</p>
            </div>
            <div className="media">
              <StaticImage
                  className="rounded-circle mr-3"
                  src="../assets/images/testimonials/avatar_google.jpeg"
                  alt="avatar"
                  layout="fixed"
                  width={50}
                  height={50}
              />
              <div className="media-body">
                <i>“{t('homePage.services.2.testimonial')}”</i>
                <div className="mt-2">
                  <h4 className="mb-0">Peter Staunton</h4>
                  <small className="d-block">Senior Software Engineering Manager at Google</small>
                </div>
              </div>
            </div>
          </div>
          <figure className="col-lg-6 order-lg-1 text-center" data-aos="fade-right">
            <img
              className="img-fluid w-lg-80"
              src={cloudHostingSvg}
              alt="cloud hosting illustration"
            />
          </figure>
        </div>
      </section>

      <SVG />

      <section className="bg-light">
        <div className="container space-2 space-lg-3">
          <div className="row justify-content-lg-between align-items-lg-center">
            <div className="col-lg-6 mb-5 mb-lg-0" data-aos="fade-right">
              <div className="mb-4">
                <h2>{t('homePage.services.3.title')}</h2>
                <p>{t('homePage.services.3.subtitle')}</p>
              </div>
              <ul className="list-style pl-4">
                <li className="mb-3">{t('homePage.services.3.stack_1')}</li>
                <li className="mb-4">{t('homePage.services.3.stack_2')}</li>
              </ul>
              <div className="media">
                <StaticImage
                  className="rounded-circle mr-3"
                  src="../assets/images/testimonials/avatar_groupon.jpeg"
                  alt="avatar"
                  layout="fixed"
                  width={50}
                  height={50}
                />
                <div className="media-body">
                  <i>“{t('homePage.services.3.testimonial')}”</i>
                  <div className="mt-2">
                    <h4 className="mb-0">Paddy Benson</h4>
                    <small className="d-block">CTO at Groupon EMEA</small>
                  </div>
                </div>
              </div>
            </div>
            <figure className="col-lg-6 text-center" data-aos="fade-left">
              <img className="img-fluid w-lg-80" src={webAppSvg} alt="web app illustration" />
            </figure>
          </div>
        </div>
      </section>

      <SVG type="ShapeBottom" />

      <section className="overflow-hidden">
        <div className="container space-2 space-top-lg-3 space-bottom-lg-4">
          <div className="row flex-column align-items-center">
            <h2 className="col-lg-7 text-center mb-5">{t('homePage.services.4.title')}</h2>
          </div>
          <div className="row justify-content-lg-center align-items-md-center">
            <div className="col-md-5 mb-6 mb-md-0">
              <div className="position-relative">
                <StaticImage
                  className="img-fluid rounded-lg"
                  src="../assets/images/profile_image_lg.png"
                  alt="avatar"
                  layout="fixed"
                  width={445}
                  height={500}
                />
                <figure className="max-w-19rem w-100 position-absolute bottom-0 left-0 z-index-n1">
                  <div className="mb-n5 ml-n5">
                    <img className="img-fluid" src={dot2Svg} alt="dot" />
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-md-6 col-lg-5">
              <div className="pl-lg-5">
                <p>{t('homePage.services.4.subtitle')}</p>
                <p>
                  <i>“{t('homePage.services.4.testimonial')}”</i>
                </p>

                <h4 className="mb-2">Martin Micunda</h4>
                {/* <p className="small">Executive Director</p> */}
                <ul className="list-inline mb-0">
                  <li className="list-inline-item pr-1">
                    <a
                      className="btn btn-xs btn-icon btn-soft-secondary"
                      href="https://twitter.com/martinmicunda"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Twitter"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li className="list-inline-item pr-1">
                    <a
                      className="btn btn-xs btn-icon btn-soft-secondary"
                      href="https://www.linkedin.com/in/martinmicunda/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark rounded mx-3 mx-xl-6">
        <div className="container-xl container-fluid space-1 space-md-2">
          <div className="px-3">
            <div className="w-md-80 text-center mx-md-auto mb-5 mb-md-6 px-4 px-md-5 px-lg-6">
              <h2 className="text-white">{t('homePage.products.title')}</h2>
            </div>
            <div className="card-deck">
              <div className="card mb-5 mb-sm-0" data-aos="fade-up">
                <img
                  className="card-img-top py-4"
                  src={schedulinoLogo}
                  alt="Schedulino Logo"
                  style={{ height: `${200}px` }}
                />
                <div className="card-body">
                  <p>{t('caseStudies.1.text')}</p>
                  <p className="mb-0">
                    <FontAwesomeIcon icon={faCog} fixedWidth /> {t('label.technologies')}:{' '}
                    <i>Angular, Typescript, Node.js, PWA, Serverless, Microservices, AWS</i>
                  </p>
                </div>
                <div className="card-footer border-0 pt-0 pb-3">
                  <LocalizedLink
                    to="/case-studies/schedulino"
                    className="card-link font-weight-bold"
                  >
                    {t('label.seeCaseStudy')} <FontAwesomeIcon icon={faAngleRight} fixedWidth />
                  </LocalizedLink>
                </div>
              </div>

              <div className="card mb-5 mb-sm-0" data-aos="fade-up" data-aos-delay="100">
                <img
                  className="card-img-top"
                  src={bordGaisEnergyLogo}
                  alt="Bord Gáis Energy Logo"
                  style={{ height: `${200}px` }}
                />
                <div className="card-body">
                  <p>{t('caseStudies.2.text')}</p>
                  <p className="mb-0">
                    <FontAwesomeIcon icon={faCog} fixedWidth /> {t('label.technologies')}:{' '}
                    <i>Typescript, Node.js, Serverless, Microservices, AWS</i>
                  </p>
                </div>
                <div className="card-footer border-0 pt-0 pb-3">
                  <a
                    href="https://www.bordgaisenergy.ie/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="card-link font-weight-bold"
                  >
                    {t('label.visitWebsite')} <FontAwesomeIcon icon={faAngleRight} fixedWidth />
                  </a>
                </div>
              </div>

              <div className="card" data-aos="fade-up" data-aos-delay="200">
                <img
                  className="card-img-top px-5"
                  src={roomexLogo}
                  alt="Roomex Logo"
                  style={{ height: `${200}px` }}
                />
                <div className="card-body">
                  <p>{t('caseStudies.3.text')}</p>
                  <p className="mb-0">
                    <FontAwesomeIcon icon={faCog} fixedWidth /> {t('label.technologies')}:{' '}
                    <i>Angular, Typescript, Node.js</i>
                  </p>
                </div>
                <div className="card-footer border-0 pt-0 pb-3">
                  <LocalizedLink to="/case-studies/roomex" className="card-link font-weight-bold">
                    {t('label.seeCaseStudy')} <FontAwesomeIcon icon={faAngleRight} fixedWidth />
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<section className="container space-top-2 space-top-lg-3">*/}
      {/*  <div className="row flex-column align-items-center text-center mb-5">*/}
      {/*    <h2 className="col-lg-7">{t('homePage.blog.title')}</h2>*/}
      {/*    <p className="col-12 mb-0">{t('homePage.blog.subtitle')}</p>*/}
      {/*  </div>*/}

      {/*  <div className="row">*/}
      {/*    {posts.map((post, index) => {*/}
      {/*      const title = post.frontmatter.title || post.fields.slug;*/}

      {/*      return (*/}
      {/*        <div*/}
      {/*          // eslint-disable-next-line react/no-array-index-key*/}
      {/*          key={index}*/}
      {/*          className="col-lg-4 col-md-6 col-sm-12 mb-5"*/}
      {/*          data-aos="fade-up"*/}
      {/*          data-aos-delay={index * 100}*/}
      {/*        >*/}
      {/*          <article*/}
      {/*            className="card h-100 shadow-sm"*/}
      {/*            itemScope*/}
      {/*            itemType="http://schema.org/Article"*/}
      {/*          >*/}
      {/*            <GatsbyImage*/}
      {/*              image={getImage(post.frontmatter.image)}*/}
      {/*              style={{ height: `${250}px` }}*/}
      {/*              className="card-img-top pb-4"*/}
      {/*            />*/}
      {/*            <div className="card-body pt-0">*/}
      {/*              <header>*/}
      {/*                <h5>{title}</h5>*/}
      {/*              </header>*/}
      {/*              <section>*/}
      {/*                <p*/}
      {/*                  dangerouslySetInnerHTML={{*/}
      {/*                    __html: post.frontmatter.description || post.excerpt,*/}
      {/*                  }}*/}
      {/*                  itemProp="description"*/}
      {/*                  className="mb-0"*/}
      {/*                />*/}
      {/*              </section>*/}
      {/*            </div>*/}
      {/*            <div className="card-footer border-0 pt-0 pb-3">*/}
      {/*              <p className="mb-0">*/}
      {/*                <small className="text-muted">{post.frontmatter.date}</small>*/}
      {/*              </p>*/}
      {/*              <Link*/}
      {/*                to={post.fields.slug}*/}
      {/*                itemProp="url"*/}
      {/*                className="card-link font-weight-bold"*/}
      {/*              >*/}
      {/*                {t('label.readMore')} <FontAwesomeIcon icon={faAngleRight} fixedWidth />*/}
      {/*              </Link>*/}
      {/*            </div>*/}
      {/*          </article>*/}
      {/*        </div>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </div>*/}

      {/*  <div className="position-relative z-index-2 text-center mt-2">*/}
      {/*    <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">*/}
      {/*      {t('label.wantToReadMore')}{' '}*/}
      {/*      <LocalizedLink to="/blog" className="font-weight-bold ml-2">*/}
      {/*        {t('label.goHere')}{' '}*/}
      {/*        <FontAwesomeIcon icon={faAngleRight} fixedWidth className="ml-1" />*/}
      {/*      </LocalizedLink>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      <div className="overflow-hidden space-top-lg-3 space-bottom-lg-1">
        <div className="position-relative gradient-y-gray">
          <div className="container-fluid space-2 space-lg-0 space-bottom-lg-3">
            <div className="row justify-content-sm-center align-items-lg-center text-center">
              <div className="col-lg-4 d-none d-lg-block">
                <div data-aos="fade-up">
                  <div className="max-w-50rem w-100 transform-rotate-3">
                    <div className="device device-ipad">
                      <img className="device-ipad-frame" src={ipadSvg} alt="iPad" />
                      <StaticImage
                        className="device-ipad-screen"
                        style={{ top: '3%' }}
                        src="../assets/images/case-studies/schedulino_landing_page.png"
                        alt="layout"
                        layout="constrained"
                      />
                    </div>

                    <figure className="max-w-19rem w-100 position-absolute bottom-0 right-0 z-index-n1 mr-n6 mb-n5">
                      <img className="img-fluid" src={dot2Svg} alt="dot" />
                    </figure>
                  </div>
                </div>
              </div>

              <div className="col-sm-8 col-lg-4">
                <h2>{t('homePage.footer.title')}</h2>
                <p>{t('homePage.footer.subtitle')}</p>

                <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover mt-2">
                  {t('homePage.footer.cta')}
                </LocalizedLink>
              </div>

              <div className="col-lg-4 d-none d-lg-block">
                <div data-aos="fade-up" data-aos-delay="200">
                  <div className="max-w-33rem w-100 transform-rotate-2 ml-auto">
                    <div className="device device-iphone-x">
                      <img className="device-iphone-x-frame" src={iphoneX} alt="iPhone" />
                      <StaticImage
                        className="device-iphone-x-screen"
                        src="../assets/images/case-studies/schedulino_mobile2.png"
                        alt="layout"
                        layout="constrained"
                      />
                    </div>

                    <figure className="max-w-19rem w-100 position-absolute top-0 left-0 z-index-n1 mt-n5 ml-n5">
                      <img className="img-fluid" src={dot2Svg} alt="dot" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-lg-block content-centered-y w-100 h-75 z-index-n1" />

          <figure className="position-absolute bottom-0 right-0 left-0">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
            >
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
            </svg>
          </figure>
        </div>
      </div>

      <section className="container space-top-1 space-bottom-2 space-bottom-lg-3">
        <SocialProof showAll />
      </section>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query indexPage($locale: String) {
    allMarkdownRemark(
      limit: 3
      filter: { fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
          locale
          readingTime {
            minutes
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          image {
            childImageSharp {
              gatsbyImageData(placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;
